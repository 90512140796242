<template>
  <section class="py-4 box-my-account">
    <div class="container">
      <h1 class="text-center border-bottom30">Find Your Style</h1>
      <div class="row no-gutters row-cols-2 row-cols-lg-5" :class="{'justify-content-md-center' : collections.length < 5}">
        <div class="col" v-for="(collection, i) in collections" :key="i">
          <router-link
            class="find-your-style-container m-2"
            :to="`/products/collection/${$helpers.format.slug(`${collection.name} ${collection.id}`)}`"
            @click.native="firebaseSelectCollection(collection)"
          >
            <img :src="collection.image_square" :alt="setCollectionImgAltText(collection)" fluid-grow />
            <div class="find-your-style-text-container">
              <div class="text-boxed">
                <b-img-lazy
                  v-if="collection.brand"
                  height="96"
                  class="rounded-circle shadow"
                  :src="collection.brand.image_banner_square"
                  :alt="collection.brand_name"
                  :title="collection.brand_name"  
                />
                {{collection.name}}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CollectionsPage",
  data() {
    return {
      collections: []
    }
  },
  computed: {
    data() {
      return {
        brands: this.$store.getters.getBrandsList,
        collections: this.$store.getters.getCollectionsList
      }
    },
  },
  mounted() {
    this.mapCollections(this.data)
  },
  methods: {
    mapCollections(data) {
      if (!data) return
      this.collections = data.collections.map(el => {
        el.brand = data.brands.find(({name}) => name == el.brand_name) ?? null
        return el
      })
    },
    firebaseSelectCollection(data) {
      this.$firebaseLogEvent("select_collection", {
        collection_id: data.id.toString(),
        collection_name: data.name,
      });
    },
    defaultBanner(img) {
      img.target.src = require("@/assets/img/banner-01.jpg");
    },
    setCollectionImgAltText(collection) {
      return `${collection.name}${!/collection/i.test(collection.name) ? " Collection": ""}`
      // return `${product.brand_name} ${product.name}${product.kadar > 0 ? ` with purity of ${product.kadar}` : ''}`
    },
  },
  watch: {
    data(newVal) {
      this.mapCollections(newVal)
    },
    collections(val) {
      if (!val.length) return
      setTimeout(() => {
        window.prerenderReady = true;
      }, 2000)
    }
  }
};
</script>
